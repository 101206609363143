import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import BlogRoll from '../components/BlogRoll'
import Breadcrumbs from '../components/Breadcrumbs'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import TitleImage from '../components/TitleImage'

export const StoriesPageTemplate = ({ title, image, heading, content, contentComponent, displayStories, location }) => {
  const PageContent = contentComponent || Content

  return (
    <React.Fragment>
      {!!image ? (
        <TitleImage title={title} image={image.childImageSharp.fluid} />
      ) : null }
      <section className="section section--gradient">
        <div className="container">
          <Breadcrumbs pathname={location?.pathname}/>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <div className="content">
                  {!!heading ? (<PageTitle title={heading}/>) : null }
                  <PageContent className="content" content={content} />
                  <br />
                  <BlogRoll displayTags={displayStories}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

StoriesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  displayStories: PropTypes.array
}

const StoriesPage = ({ data, location }) => {
  const { markdownRemark: pageData } = data

  return (
    <Layout>
      <StoriesPageTemplate
        title={pageData.frontmatter.title}
        image={pageData.frontmatter.image}
        heading={pageData.frontmatter.heading}
        content={pageData.html}
        contentComponent={HTMLContent}
        displayStories={pageData.frontmatter.displayStories}
        location={location}
      />
    </Layout>
  )
}

StoriesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StoriesPage

export const storiesPageQuery = graphql`
  query StoriesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        displayStories
      }
    }
  }
`
